<template>
  <ib-dialog
    id="dialog-story-mode-add-product"
    empty-header
    :visible="dialogAddProductVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >

    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addProductOrService') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addProductOrService') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3 d-block d-xl-none"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.product.addDialog.guide.title')"
        :text="$t('pages.businessGuide.product.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Ai Buddy -->
      <ai-buddy
        type="product"
        class="ai-buddy-margin-bottom d-block d-xl-none"
        @suggestion="form.name = $event"
      />
      <!-- Ai Buddy -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="30">

            <!-- Left Side -->
            <el-col :md="12" :xl="7">

              <!-- Avatar setter -->
              <!--              <avatar-setter v-if="cropping" @click="cropImage" />-->
              <!-- /Avatar setter -->

              <!-- Form Wrapper -->
              <div class="product-form-wrapper">
                <!-- Avatar uploader -->
                <ib-avatar-uploader
                  ref="avatarUploader"
                  :image="form.image"
                  :default-image="require('@/assets/img/icons/story-mode/gift.svg')"
                  @cropping="cropping = true"
                  @change="form.croppedImage = $event"
                  @crop="cropImage"
                  @delete="deleteImage"
                />
                <!-- /Avatar uploader -->

                <!-- Form Inner -->
                <div class="product-form-inner">

                  <!-- Form -->
                  <el-form ref="form" :model="form" @submit.native.prevent>
                    <!-- Product Or Service Name -->
                    <ib-input
                      v-model="form.name"
                      :label="$t('pages.businessGuide.product.addDialog.fields.productOrServiceName')"
                      :maxlength="50"
                    />
                    <!-- /Product Or Service Name -->

                    <!-- Description -->
                    <el-form-item>
                      <el-input
                        v-model="form.description"
                        type="textarea"
                        maxlength="500"
                        :rows="4"
                        class=""
                        :placeholder="$t('pages.businessGuide.product.addDialog.fields.shortDescription')"
                        resize="none"
                      />
                    </el-form-item>
                    <!-- /Description -->

                  </el-form>
                  <!-- /Form -->

                  <!-- Key Differentiators Title  -->
                  <p>{{ $t('pages.businessGuide.product.addDialog.fields.keyDifferentiators') }}</p>
                  <!-- /Key Differentiators Title  -->

                  <!-- Key Differentiators Input -->
                  <el-input
                    v-model="form.differentiators"
                    class="key-differentiators"
                    :placeholder="$t('pages.businessGuide.product.addDialog.fields.enterHere')"
                    maxlength="50"
                  />
                  <!-- /Key Differentiators Input -->

                </div>
                <!-- /Form Inner -->

              </div>
              <!-- /Form Wrapper -->

              <!-- Dialog Bottom -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialog">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="form.name === ''"
                      :loading="loading"
                      @click="saveProduct"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
              </div>
              <!-- /Dialog Bottom -->

            </el-col>
            <!-- /Left Side -->

            <!-- Center -->
            <el-col :md="12" :xl="7">
              <template v-if="!getProducts.length">
                <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                  <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                  <p class="add-dialog-folder-text font-outfit-regular">
                    {{ $t('createdItemsWillAppearHere') }}
                  </p>
                </div>
              </template>

              <!-- Products Added -->
              <div ref="cardHolder" class="products-added">
                <!--:options="{disabled: hasAccess}"-->
                <ib-draggable
                  :list="getProducts"
                  draggable=".product"
                  @change="onChangeProductOrder"
                  @start="onStartProductOrder"
                >
                  <!-- Products Added List -->
                  <div
                    v-for="product in getProducts"
                    :key="product.id"
                    class="product cursor-move"
                    @click="onEditProduct(product)"
                  >

                    <!-- Product Image & Name -->
                    <div class="product-left">

                      <!-- Default Image -->
                      <div v-if="product.image === null" class="img-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="90" viewBox="0 0 100 90" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#3E474F" d="M7.5 0C3.365 0 0 3.365 0 7.5V30h5v50c0 5.515 4.485 10 10 10h70c5.515 0 10-4.485 10-10V30h5V7.5c0-4.135-3.365-7.5-7.5-7.5h-85zM10 10h30v10H10V10zm50 0h30v10H60V10zM15 30h25v10h20V30h25l.01 50H15V30z" opacity=".1"/></svg>
                      </div>
                      <!-- /Default Image -->

                      <!-- Product Image -->
                      <div v-else>
                        <div
                          class="img-wrapper img-wrapper-filled"
                          :style="cardImageBySize(product.image, 'small')"
                        />
                      </div>
                      <!-- /Product Image -->

                      <!-- Product Name -->
                      <p class="product-name">
                        {{ product.name }}
                      </p>
                      <!-- /Product Name -->

                    </div>
                    <!-- /Product Image & Name -->

                    <!-- Actions Dropdown -->
                    <actions-dropdown
                      ref="itemDropdownWrapper"
                      :entity="product"
                      :delete-disabled="form.id === product.id"
                      :edit-disabled="form.id === product.id"
                      @edit="onEditProduct"
                      @copy="onCopyProduct"
                      @delete="deleteStoryModeProduct($event.id)"
                    />
                    <!-- /Actions Dropdown -->

                  </div>
                </ib-draggable>
                <!-- /Products Added List -->
                <erase-icon-button
                  v-if="removeButtonVisible"
                  confirm
                  height="45px"
                  :loading="removeLoadingExamples"
                  @confirm="deleteExamples"
                />
              </div>
              <!-- /Products Added -->

            </el-col>
            <!-- /Center -->

            <!-- Right Side -->
            <el-col :xl="10">
              <!-- Info Guide -->
              <info-guide-add-dialog
                class="mb-3 d-none d-xl-block"
                :visible="guideVisible"
                :title="$t('pages.businessGuide.product.addDialog.guide.title')"
                :text="$t('pages.businessGuide.product.addDialog.guide.text')"
                @click="onToggleDialogGuide"
              />
              <!-- Info Guide -->

              <!-- Ai Buddy -->
              <ai-buddy
                type="product"
                class="ai-buddy-margin-bottom d-none d-xl-block"
                @suggestion="form.name = $event"
              />
              <!-- Ai Buddy -->
            </el-col>
            <!-- /Right Side -->
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
// import AvatarSetter from '@/views/Home/StoryMode/Components/AvatarSetter'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IbAvatarUploader from '@/components/_v2/IbAvatarUploader'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'

export default {
  name: 'DialogAddProduct',

  components: {
    AiBuddy,
    InfoGuideAddDialog,
    // AvatarSetter,
    EraseIconButton,
    AddDialogHeader,
    IbAvatarUploader,
    ActionsDropdown
  },

  mixins: [
    MixinCloseMenu,
    MixinDeleteIdeaExamples,
    MixinDialog,
    MixinGuide,
    MixinIdeaRoles
  ],

  props: {
    dialogAddProductVisible: {
      type: Boolean,
      default: false
    },
    productAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      cropping: false,
      guideVisible: false,
      form: {
        id: 0,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        ideaId: this.$store.state.idea.id,
        name: '',
        description: '',
        differentiators: ''
      }
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getProducts',
      'getHasItemsFromTemplate'
    ]),

    isDialogOpen () {
      return this.dialogAddProductVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getProducts')
    }
  },

  watch: {
    'form.name' () {
      this.form.name = this.form.name.replace(/^\s+/g, '')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeProduct',
      'updateOrderNumber',
      'deleteIdeaExamples'
    ]),

    onStartProductOrder () {
      this.$refs.itemDropdownWrapper.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeProductOrder (data) {
      this.updateOrderNumber({
        type: 'product',
        data,
        oldOrderData: this.getProducts,
        mutation: 'setProduct'
      })
    },

    closeDialog () {
      this.$emit('close-dialog-add-product')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addProductGuide')
      if (this.productAction) {
        this.form.id = this.productAction.id
        this.form.ideaId = this.$store.state.idea.id
        this.form.image = this.productAction.image
        this.form.name = this.productAction.name
        this.form.description = this.productAction.description
        this.form.differentiators = this.productAction.differentiators
      }
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        name: '',
        description: '',
        differentiators: ''
      }
      this.cropping = false
      this.$refs.avatarUploader.reset()
    },

    resetGuide () {
      if (this.$store.getters['idea/getProducts'].length === 0) {
        this.guideVisible = false
      }
    },

    saveProduct () {
      this.loading = true
      if (this.cropping) {
        this.cropImage()
      }
      if (this.form.id === 0) {
        this.addProduct()
      } else {
        this.updateProduct()
      }
    },

    addProduct () {
      delete this.form.uploadedImage
      this.$http.post(
        'story-mode/concept/product/products',
        this.form
      ).then((response) => {
        if (response.status === 201) {
          this.resetGuide()
          this.$store.commit('idea/addProduct', response.data.payload.product)
          this.resetForm()
        }
      }).finally(() => {
        this.loading = false
      })
    },

    updateProduct () {
      this.$http.put(
        'story-mode/concept/product/products/' + this.form.id,
        this.form
      ).then((response) => {
        if (response.status === 200) {
          this.$store.commit('idea/updateProduct', response.data.payload.product)
          this.resetForm()
        }
      }).finally(() => {
        this.loading = false
      })
    },

    onEditProduct (product) {
      this.resetForm()
      this.form = Object.assign({}, product)
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      this.scrollToTop()
    },

    onCopyProduct (product) {
      this.resetForm()
      this.form = Object.assign({}, product)
      this.form.id = 0
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      this.scrollToTop()
    },

    deleteImage () {
      this.form.image = null
      this.form.uploadedImage = null
      this.form.croppedImage = null
    },

    cropImage () {
      this.cropping = false
      const image = this.$refs.avatarUploader.cropImage()
      if (image) {
        this.form.croppedImage = image.croppedImage
        this.form.uploadedImage = image.uploadedImage

        return
      }
      this.deleteImage()
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('products')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

 .add-dialog-folder-icon {
   width: 80px;
   @include media-breakpoint-up($xxl) {
     width: 100px;
   }
 }

 .add-dialog-folder-text {
   font-size: 14px;
   @include media-breakpoint-up($xxl) {
     font-size: 16px;
   }
 }

</style>
